import '../App.css';

const ImageCarousel = () => {

  return (
    <div id="slideshow">
      <div class="container">
        <img src={"images/together.jpg"}/>
        <img src={"images/ashu-dadi.jpg"}/>
        <img src={"images/ashu-parents.jpg"}/>
        <img src={"images/ashu-fam.jpg"}/>
        <img src={"images/gargic-fam.jpg"}/>
        <img src={"images/ashu-fam-2.jpg"}/>
        <img src={"images/gargic-fam-2.jpg"}/>
        <img src={"images/cousins.jpg"}/>
        <img src={"images/jiaji.jpg"}/>
        <img src={"images/ashu-pa-bro.jpg"}/>
      </div>
    </div>
  );
}

export default ImageCarousel;
