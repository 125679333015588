import '../App.css';

const OlaUberNavigation = () => {

  const onOlaSelect = () => {
    window.open("https://olawebcdn.com/assets/ola-universal-link.html?utm_source=xapp_token&landing_page=bk&drop_lat=26.921848103518517&drop_lng=75.6843762576721", '_blank')
  }

  const onUberSelect = () => {
    window.open("https://m.uber.com/ul/?action=setPickup&client_id=N%2CA&pickup=my_location&dropoff[formatted_address]=Swarna%20Bhoomi%2C%20Sirsi%20Rd%2C%20near%20Sirsi%20Mode%2C%20Khatipura%2C%20Jaipur%2C%20Rajasthan%2C%20India&dropoff[latitude]=26.921848103518517&dropoff[longitude]=75.6843762576721", '_blank')
  }

  const onViewOnMapSelect = () => {
    window.open('https://maps.app.goo.gl/AxczZyPXWyqANCWn7', '_blank')
  }

  return (
    <div className="footerNav">
      <button className="btn mapsBtn" onClick={onViewOnMapSelect}>
        <img src={'map-image.jpeg'} className="olaImg"/>
        View On Maps
      </button>
      <footer className="footerCont">
        <button className="btn uberBtn" onClick={onUberSelect}>
          Book An Uber
        </button>
        <button className="btn olaBtn" onClick={onOlaSelect}>
          <img src={'ola-image.png'} className="olaImg"/>
          Book An Ola
        </button>
      </footer>
    </div>
  );
}

export default OlaUberNavigation;
